import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LisFormFieldExtComponent, LisHtmlInputEvent } from '@lis-form';
import { getFileStatusToast, getStatusTypeByStatusMessage } from '@lis-helpers';
import { ApiService, FileUploaderService, ToastService } from '@lis-services';
import {
  LIS_API_ENDPOINTS,
  LisDocumentStatusRequest,
  LisDocumentStatusResponse,
  LisFile,
  LisFileStatus,
  LisFileType,
} from '@lis-types';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent
  extends LisFormFieldExtComponent
  implements OnInit
{
  @Input({ required: true }) override control?: FormControl<LisFile[] | null>;
  @Input() accept?: LisFileType | LisFileType[];

  @ViewChild('dragArea') dragAreaRef?: ElementRef<HTMLDivElement>;

  public files?: LisFile[];

  public isDragDropActive = false;
  private subscriptions = new Subscription();

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private apiService: ApiService,
    private cdRef: ChangeDetectorRef,
    private fileUploaderService: FileUploaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenForControlChanges();
  }

  @HostListener('dragover', ['$event']) public dragOver(
    event: DragEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isDragDropActive) {
      this.dragAreaRef?.nativeElement.classList.add('bg-interactive-hover');
    }
    this.isDragDropActive = true;
  }
  @HostListener('dragleave', ['$event']) public dragleave(
    event: DragEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragAreaRef?.nativeElement.classList.remove('bg-interactive-hover');
    this.isDragDropActive = false;
  }

  @HostListener('drop', ['$event']) public drop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragAreaRef?.nativeElement.classList.remove('bg-interactive-hover');
    this.isDragDropActive = false;
    const files = event.dataTransfer?.files;

    this.addFilesToList(files as FileList);
  }

  private listenForControlChanges(): void {
    this.subscriptions.add(
      this.control?.valueChanges.subscribe((value) => {
        this.files = value ?? [];
        this.cdRef.markForCheck();
      })
    );
  }

  public onInputFileChanged(event_: Event): void {
    const event = event_ as LisHtmlInputEvent;
    if (!event || !event.target) {
      throw new Error('Files could not be added');
    }

    this.addFilesToList(event.target.files as FileList);

    event.target.value = '';

    this.cdRef.detectChanges();
  }

  private async addFilesToList(filelist: FileList): Promise<void> {
    const files = Object.values(filelist ?? {}) ?? [];

    if (!files) {
      return;
    }

    const filteredFiles = files.filter((file) => {
      if (
        this.accept &&
        ((isArray(this.accept) &&
          !this.accept.includes(file.type as LisFileType)) ||
          (!isArray(this.accept) && this.accept !== file.type))
      ) {
        this.toastService.show({
          type: 'error',
          title: `${this.translateService.instant('docs.toast-error.wrong-filetype.title')}: ${file.name}!`,
          subtitle: 'docs.toast-error.wrong-filetype.subtitle',
        });
        return false;
      }

      // check if file is smaller than 200MB
      if (file.size > 200 * 1024 * 1024) {
        this.toastService.show({
          type: 'error',
          title: `${this.translateService.instant('docs.toast-error.file-too-large.title')}: ${file.name}!`,
          subtitle: 'docs.toast-error.file-too-large.subtitle',
        });
        return false;
      }

      if (this.files?.some((f) => f.file.name === file.name)) {
        this.toastService.show({
          type: 'error',
          title: `${this.translateService.instant('docs.toast-error.rename-file.title')}: ${file.name}!`,
          subtitle: 'docs.toast-error.rename-file.subtitle',
        });
        return false;
      }
      return true;
    });
    const data = this.fileUploaderService.currentStatusRequestBody
      ? this.fileUploaderService.currentStatusRequestBody
      : null;

    if (!data) {
      return;
    }

    const newFiles: LisFile[] = [];

    for (const file of filteredFiles) {
      data.fileName = file.name;

      let status: LisDocumentStatusResponse;
      if (data.productId && data.category) {
        status = await this.apiService.post<
          LisDocumentStatusRequest,
          LisDocumentStatusResponse
        >(LIS_API_ENDPOINTS.DOCS_STATUS, {
          data,
        });
        newFiles.push({
          file,
          status: status?.status ?? undefined,
          statusType: getStatusTypeByStatusMessage(status.status),
        });
      } else {
        newFiles.push({
          file,
        });
      }
    }

    const allFiles: LisFile[] = [...(this.files ?? []), ...newFiles];
    this.control?.setValue(allFiles);
    this.cdRef.markForCheck();
  }

  public onRemoveFileClick(file: File): void {
    this.files = (this.files ?? []).filter((f) => f.file !== file);
    this.control?.setValue(this.files.map((f) => f));
  }

  public getStatusToastContent(status: LisFileStatus): {
    title: string;
    subtitle: string;
  } {
    return {
      title: getFileStatusToast(status).title,
      subtitle: getFileStatusToast(status).subtitle,
    };
  }

  public trackByIndex(index: number): number {
    return index;
  }
}
